import messages from './data/messages.js'

export default defineI18nConfig(() => {
  const config = useRuntimeConfig()
  const locale = config?.public?.locale || 'sv'
 
  return {
    legacy: false,
    locale: locale,
    messages: {
      en: messages.en,
      sv: messages.sv
    }
  }
})